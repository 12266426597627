export const updateChildren = (children, properties) => {
    return [
        {
            ...children,
            props: {
                ...children.props,
                children: {
                    ...children.props.children,
                    props: {
                        ...children.props.children.props,
                        children: {
                            ...children.props.children.props.children,
                            props: {
                                ...children.props.children.props.children.props,
                                ...properties,
                            },
                        },
                    },
                },
            },
        },
    ]
}

export const SettingsMessage = ({ title, description, containerStyle }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "rgba(136, 85, 255, 0.1)",
                overflow: "hidden",
                ...containerStyle,
            }}
        >
            <span role="img" aria-label="icon" style={{ fontSize: "32px" }}>
                ✨
            </span>
            <div style={{ maxWidth: "240px" }}>
                <h1 style={{ fontSize: 11, color: "#96F", fontWeight: 600 }}>
                    {title}
                </h1>
                <p
                    style={{
                        fontSize: 11,
                        color: "rgba(153, 102, 255, 0.7)",
                        lineHeight: 1.5,
                    }}
                >
                    {description}
                </p>
            </div>
        </div>
    )
}

export const isTextOverLimit = (text: string, maxLength: number) => {
    return text.length > maxLength
}

export const truncateText = (text: string, maxLength: number) => {
    // Verifica se o comprimento do texto é maior que o máximo permitido
    if (isTextOverLimit(text, maxLength)) {
        // Corta o texto e adiciona '...' no final
        return text.substring(0, maxLength - 3) + "..."
    }
    return text // Retorna o texto original se for menor ou igual ao máximo permitido
}
